<template>
  <div>
    <div>
      <img class="comimg" style="width: 100%" src="@/assets/img/home_banner.png">
    </div>
    <div class="res" style="height: 720px">
      <img class="comimg" style="width: 100%" src="@/assets/img/bg_01.png">
      <div class="abs home-p-com">
        <div class="com-page-t">企修通</div>
        <div style="text-align: center">集设备运维、管理、处置三位于一体的智能SaaS软件</div>
        <div style="display: flex;justify-content: center;margin-top: 50px">
          <div v-for="(item,k) in twoArr" :key="k" class="t-sub-v">
            <div class="cflex" style="width: 110px;height: 110px">
              <img :src="item.icon">
            </div>
            <div class="t-sub-t">{{item.title}}</div>
            <div class="t-sub-sub"><img class="t-img-icon" src="@/assets/img/icon_tick.png"/>{{item.o}}
            </div>
            <div class="t-sub-sub"><img class="t-img-icon" src="@/assets/img/icon_tick.png"/>{{item.t}}
            </div>
            <div class="t-sub-sub"><img class="t-img-icon" src="@/assets/img/icon_tick.png"/>{{item.th}}</div>
            <div class="t-sub-sub"><img class="t-img-icon" src="@/assets/img/icon_tick.png"/>{{item.f}}</div>
            <div v-if="item.five.length>0" class="t-sub-sub">
              <img class="t-img-icon"
                   src="@/assets/img/icon_tick.png"/>{{item.five}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 754px">
      <img class="comimg" style="width: 100%" src="@/assets/img/bg_02.png">
      <div class="abs home-p-com">
        <div class="com-page-t">丰富、安全、高效的产品及服务</div>
        <div class="three-bg">
          <div v-for="(item,i) in threeArr" :key="'t'+i" class="three-out">
            <div v-for="(subi,s) in item" :key="'sub'+i+s" class="three-inner">
              <img :src="subi.icon"/>
              <div class="three-sub">
                <div class="three-sub-tit">{{subi.tit}}</div>
                <div class="three-sub-desc">{{subi.desc}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 869px">
      <img class="comimg" style="width: 100%" src="@/assets/img/bg_03.png">
      <div class="abs home-p-com">
        <div class="com-page-t">全面、智能、专业的解决方案</div>
        <div style="display: flex;justify-content: space-between;margin-top: 50px">
          <div v-for="(item,k) in fourArr" :key="'f'+k" class="f-sub-v"
               @click="fourClick(item)"
          >
            <el-image
                style="width: 100%; height: 100%"
                :src="item.icon"
                fit="cover">

            </el-image>
            <div class="four-com abs">
              <div class="four-title">
                <span style="color: #185CFF">{{item.tit1}}</span>
                <br/>
                <span>{{item.tit2}}</span>
              </div>
              <div class="four-desc">{{item.desc}}</div>
              <div class="four-btn">了解更多</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="countView" style="height: 258px;background: #185CFF" class="res">
      <div style="margin: 0 auto;width: 1200px;height: 100%;justify-content: space-around" class="cflex">
        <div v-for="(five,ff) in fiveArr" :key="'five'+ff" class="five-bg">
          <div class="five-tit">
            <CountTo
                v-if="showCount"
                style="margin-right: -20px"
                :startVal='0'
                :endVal='five.count'
                :duration='2000'
            />
            <span>%</span><i style="font-weight: bold;font-size: 30px" class="el-icon-top"></i>
          </div>
          <div class="five-desc">{{five.detail}}</div>
        </div>
      </div>
      <div class="abs five-botton">数据来源：企修通系统管理后台</div>
    </div>
    <div class="res" style="height: 774px">
      <img class="comimg" style="width: 100%" src="@/assets/img/bg_04.png">
      <div class="abs home-p-com">
        <div class="com-page-t">客户的信任与选择</div>
        <div class="two-home">
          <el-carousel height="547px"
                       style="width: 100%;background: white;box-shadow: 0px 3px 20px 1px #EDF1FF;border-radius: 16px"
          >
            <el-carousel-item v-for="(item,s) in sixArr" :key="'s'+s">
              <div class="cflex" style="height: 100%;margin: 0 35px">
                <div>
                  <div class="six-tit">{{item.tit}}</div>
                  <div class="six-subtit">{{item.subtit}}</div>
                  <div class="six-detail">{{item.desc}}</div>
                </div>
                <img style="height: 407px;width: 610px;margin-left: 42px" :src="item.icon">
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="res" style="height: 924px">
      <img class="comimg" style="width: 100%" src="@/assets/img/bg_map.png">
      <div class="abs home-p-com">
        <div class="com-page-t">实现全国范围覆盖——城市落地图</div>
      </div>
    </div>
    <div class="res">
      <img class="comimg" style="width: 100%" src="@/assets/img/bg_blue.png">
      <div class="abs home-p-com cflex" style="flex-direction: column;color: white">
        <div style="text-align: center;font-weight: 600;font-size: 38px">免费试用</div>
        <div style="margin: 12px 0 31px 0;font-size: 22px;font-weight: 600;text-align: center">立即体验不一样的数字化设备管理服务</div>
        <div class="e-btn" @click="giveContract">了解更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'

export default {
  name: "index",
  components: {
    CountTo,
  },
  data () {
    return {
      selecIndex: 2,
      fourIndex: -1,
      sixArr: [
        {
          tit: '重庆市某人民医院',
          subtit: '——企修通助力企业高效、智能盘点',
          icon: require('@/assets/img/hospital.png'),
          desc: '自从使用平台后，医院内部的设施设备等的运维、盘点效率得到了极大的提升。特别是在设备盘点上，以前全靠人力手动盘点，经常会出现盘点重复、盘点结束后数量对不上，然后再次清点等情况。而用上企修通的智能盘点系统后，机器一扫所有信息都一目了然，非常方便。'
        },
        {
          tit: '西安市某连锁地产中心',
          subtit: '——企修通助力企业精细化管理',
          icon: require('@/assets/img/2.png'),
          desc: '自从使用企修通扫码报修模块以后，平时打印机、复印机出了问题以后一扫就报修，每个人都可以扫码报修，工程师接单后上门速度也快，省心许多。而且作为管理者，能够在后台看机器的维保数据，这对于后期的采购需求也有着极大地帮助。'
        },
        {
          tit: '介休市智和数码科技有限公司',
          subtit: '——企修通助力企业数字化运维管理',
          icon: require('@/assets/img/3.png'),
          desc: '企修通的一机一码的设备管理方式，方便工程师精准定位报修设备，极大地缩短了上门维修耗费的时间。统一指派工单，改变了传统的工程师管理方式，人力管理更有效。'
        },
      ],
      fiveArr: [
        {
          count: 15,
          detail: '服务营收提升',
        },
        {
          count: 20,
          detail: '服务满意度提升',
        },
        {
          count: 60,
          detail: '数据分析率提升',
        },
      ],
      fourArr: [
        {
          tit1: '全品类设备运维',
          tit2: '解决方案',
          desc: '企修通运维管理，可实现专属设备二维码+移动端网络智能报障、运维工程师指派及服务过程跟踪',
          icon: require('@/assets/img/bg_solution01.png'),
          path: '/solution',
        },
        {
          tit1: '设备智能盘点',
          tit2: '解决方案',
          desc: '快速完成非接触式批量盘点',
          icon: require('@/assets/img/bg_solution02.png'),
          path: '/pdSoluting',
        },
        {
          tit1: '专业资产处置',
          tit2: '解决方案',
          desc: '多家回收取到评估资产残值',
          icon: require('@/assets/img/bg_solution03.png'),
          path: '/zcSolution',
        }
      ],
      threeArr: [
        [
          {
            icon: require('@/assets/img/icon01.png'),
            tit: '多种功能模块，灵活组合',
            desc: '三大核心功能（运维、盘点、处置） 多种功能组件',
          },
          {
            icon: require('@/assets/img/icon02.png'),
            tit: '独立自主研发，安全认证',
            desc: '拥有独立自主知识产权，获得信创生态认证',
          }
        ],
        [
          {
            icon: require('@/assets/img/icon03.png'),
            tit: '多种技术结合，全面提效',
            desc: '二维码、物联网、RFID等多种技术手段融合，软、硬件打通',
          },
          {
            icon: require('@/assets/img/icon04.png'),
            tit: '双S模式，赋能用户',
            desc: '软件(SaaS）+服务（Service），陪伴企业全生命周期',
          }
        ]
      ],
      twoArr: [
        {
          icon: require('@/assets/img/3d_01.png'),
          title: '数字化工具',
          o: '软硬件配套',
          t: '效率可视化',
          th: '提升管理',
          f: '挖掘数据',
          five: '',
        },
        {
          icon: require('@/assets/img/3d_02.png'),
          title: '标准化服务',
          o: '服务升级',
          t: '在线协同',
          th: '服务量化',
          f: '系统驱动',
          five: '',
        },
        {
          icon: require('@/assets/img/3d_03.png'),
          title: '全场景覆盖',
          o: '一套完整系统',
          t: '贯穿核心场景',
          th: '解决用户痛点',
          f: '支持定制开发',
          five: '满足个性需求',
        },
        {
          icon: require('@/assets/img/3d_04.png'),
          title: '产业链赋能',
          o: '整合资源',
          t: '技术支持',
          th: '运营辅导',
          f: '融合赋能',
          five: '',
        },
        {
          icon: require('@/assets/img/3d_05.png'),
          title: '集成化管理',
          o: '系统功能',
          t: '数据架构',
          th: '决策控制 ',
          f: '多元化管理',
          five: '',
        },
      ],
      showCount: false,
    }
  },
  mounted () {
    this.$parent.gotoTop()
    let con = document.getElementById('qxtcont')
    con.addEventListener('scroll', this.handerScroll, true)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handerScroll)
  },
  methods: {
    handerScroll () {
      let con = document.getElementById('qxtcont')
      let scroTop = con.scrollTop;
      // let offsetTop = document.querySelector('#countView').offsetTop
      if (scroTop > 2100) {
        this.showCount = true
      }
    },
    giveContract () {
      //  留下联系方式
      this.$router.push('/free')
    },
    gotoTop () {
      //  滚动到顶部
      this.$parent.gotoTop()
    },
    twoClick (k) {
      this.selecIndex = k
    },
    fourClick (item) {
      this.$router.push(item.path)
    },
  }
}
</script>

<style scoped>
  .six-tit {
    color: #185CFF;
    font-size: 26px;
    font-weight: 600;
    line-height: 27px;
    border-left: 4px solid #185CFF;
    padding-left: 5px;
  }

  .six-subtit {
    text-align: left;
    font-size: 26px;
    font-weight: 600;
    line-height: 27px;
    margin: 15px 0 64px 0;
  }

  .six-detail {
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
  }

  .five-bg {
    color: white;
    margin-top: -30px;
  }

  .five-tit {
    font-weight: bold;
    font-size: 90px;
    text-align: center;
  }

  .five-desc {
    font-weight: 600;
    font-size: 26px;
    text-align: center;
  }

  .five-botton {
    left: 0;
    bottom: 20px;
    width: 100%;
    text-align: center;
    height: 22px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.3);
    font-weight: 400;
  }

  .four-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
  }

  .four-desc {
    color: #41464D;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    height: 100px;
  }

  .four-com {
    top: 73px;
    left: 30px;
    height: 400px;
    width: 300px;
  }

  .f-sub-v {
    height: 652px;
    border-radius: 16px;
    margin: 0px 10px;
    box-shadow: 0px 3px 6px 1px rgba(31, 95, 255, 0.1);
    background: white;
    cursor: pointer;
    overflow: hidden;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: all 0.4s ease 0s, opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s;
    transition-property: all, opacity, transform;
    transition-duration: 0.4s, 1s, 1s;
    transition-timing-function: ease, cubic-bezier(0.5, 0, 0, 1), cubic-bezier(0.5, 0, 0, 1);
    transition-delay: 0s, 0s, 0s;
  }

  .f-sub-v:hover {
    margin-top: -4px;
    box-shadow: 10px 10px 10px 1px rgba(31, 95, 255, 0.1);
  }

  .four-show {
    display: inline-block;
  }

  .f-nomal:hover .four-show {
    display: none;
  }

  .four-hide {
    display: none;
  }

  .four-btn {
    background: #185CFF;
    color: white;
    box-shadow: 0px 3px 6px 1px rgba(24, 92, 255, 0.16);
    border-radius: 4px;
    font-weight: 400;
    padding: 13px 30px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 35px;
    display: inline-block;
  }

  .three-bg {
    margin: 0 auto;
    width: 1063px;
    height: 479px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 82px;
  }

  .three-out {
    display: flex;
    justify-content: space-between;
  }

  .three-inner {
    width: 338px;
    height: 159px;
    display: flex;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 16px;

  }

  .three-inner img {
    width: 32px;
    height: 28px;
    margin: 40px 13px 0 20px;
  }

  .three-sub {
    flex: 1;
    margin-right: 30px;
    margin-top: 39px;
  }

  .three-sub-tit {
    color: #185CFF;
    font-size: 22px;
    font-weight: 600;
  }

  .three-sub-desc {
    font-size: 16px;
    font-weight: 400;
    color: #41464D;
    line-height: 27px;
    margin-top: 8px;
  }

  .com-page-t {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-top: 54px;
  }

  .qxtimg {
    width: 110px;
    height: 110px
  }

  .t-nomal {
    width: 180px;
    animation: duanwidth 1s ease-in;
  }

  .t-active {
    width: 300px;
    animation: cwidth 1s ease-in;
  }

  .t-active img {
    width: 90px;
  }

  .t-nomal img {
    width: 66px;
  }

  .t-sub-sub {
    font-weight: 400;
    color: #41464D;
    line-height: 36px;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .t-sub-sub img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .t-sub-t {
    color: #292D33;
    font-size: 24px;
    font-weight: 600;
    line-height: 76px;
    border-bottom: 1px solid rgba(24, 92, 255, 0.1);
    margin-bottom: 34px;
    cursor: pointer;
  }

  .t-sub-v {
    width: 180px;
    height: 465px;
    display: flex;
    margin: 0 10px;
    padding-top: 45px;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border-radius: 18px;
    box-shadow: 0px 0px 10px 1px rgba(31, 95, 255, 0.1);
    cursor: pointer;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: all 0.4s ease 0s, opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s;
    transition-property: all, opacity, transform;
    transition-duration: 0.4s, 1s, 1s;
    transition-timing-function: ease, cubic-bezier(0.5, 0, 0, 1), cubic-bezier(0.5, 0, 0, 1);
    transition-delay: 0s, 0s, 0s;
  }

  .t-img-icon {
    width: 0px;
    height: 110px;
    display: none;
  }

  .t-sub-v:hover {
    width: 300px;
  }

  .t-sub-v:hover .t-img-icon {
    width: 26px;
    display: inline-block;
  }

  .t-sub-v:hover .t-sub-t {
    color: #185CFF;
    font-size: 28px;
  }

  .bannerTit {
    left: 20vw;
    height: 100%;
    width: 600px;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  .two-home {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
  }

  @keyframes cwidth {
    0% {
      width: 180px;
    }
    100% {
      width: 300px;
    }
  }

  @keyframes duanwidth {
    0% {
      width: 300px;
    }
    100% {
      width: 180px;
    }
  }

  @keyframes fwidth {
    0% {
      width: 317px;
    }
    100% {
      width: 532px;
    }
  }

  @keyframes fduanwidth {
    0% {
      width: 532px;
    }
    100% {
      width: 317px;
    }
  }
</style>
