<template>
  <div class="footer">
    <div class="footer-top">
      <div class="footer-container link-list"
           style=";height: 48px;display: flex;align-items: center;">
        <span style="margin-right: 110px">友情链接</span>
        <a target="_blank" href="https://www.officemate.cn/"
        >欧菲斯办公伙伴</a
        >
        <a target="_blank" href="https://www.fufentong.com/">福分通</a>
        <a target="_blank" href="https://www.pinshangtong.cn/#/"
        >品商通</a
        >
        <a target="_blank" href="http://pfg.officemate.cn/#/home"
        >批发购</a
        >
        <a target="_blank" href="http://www.qygou.cn/#/home">企业购</a>
        <a target="_blank" href="http://bnew.officemate.cn/login"
        >合约在线</a
        >
        <a target="_blank" href="http://www.matemro.com/web/">工品伙伴</a>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-container container-top">
        <div style="width: 143px;margin-right: 127px">
          <img style="width: 143px;" src="@/assets/img/logo.png">
          <div class="code">
            <img class="code-img" src="@/assets/img/code.png" alt=""/>
          </div>
          <div class="code-title">企修通公众号</div>


        </div>
        <div>
          <div class="contract-title">联系我们</div>
          <div class="info">
            <div>
              <span class="info-sub" style="font-weight: 600;font-size: 16px">重庆企修通网络科技有限公司</span>
            </div>
            <div>
              <span class="info-sub"><i class="el-icon-message footer-icon"></i>邮箱：qixiutong@officemate.cn</span>
            </div>
            <div>
              <span class="info-sub"><i class="el-icon-location-outline footer-icon"></i>地址：重庆市渝北区金开大道西段106号1幢</span>
            </div>
          </div>
        </div>
        <div>
          <div class="contract-title">规则中心</div>
          <div class="info">
            <div class="foot-ruler">
              <router-link :to="jump(1)">平台服务协议</router-link>
              <router-link :to="jump(6)">知识产品声明</router-link>
            </div>
            <div class="foot-ruler">
              <router-link :to="jump(2)">隐私保护政策</router-link>
              <router-link :to="jump(7)">代理商手册</router-link>
            </div>
            <div class="foot-ruler">
              <router-link :to="jump(4)">用户行为规范</router-link>
              <router-link :to="jump(3)">销售合同</router-link>
            </div>
            <div class="foot-ruler">
              <router-link :to="jump(5)">账户管理规范</router-link>
            </div>
          </div>
        </div>
        <div>
          <div style="font-size: 18px;font-weight: 600;margin-bottom: 20px">023-8619 3648</div>
          <span class="callPhone">咨询热线</span>
        </div>
      </div>
    </div>
    <div class="footer-bottom record-info cflex" style="height: 65px;border-top: 1px solid rgba(255,255,255,0.3);">
      <a href="https://beian.miit.gov.cn" style="margin-right: 40px" target="_blank"
      >渝ICP备20000612号-1</a
      >
      <!--      <img src="/police.png" style="float: left;"/>-->
      <!-- <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010302003058"
          target="_blank"
      >渝公网安备 50010302003058号</a
      > -->
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50019002504160" rel="noreferrer" target="_blank">渝公网安备 50019002504160号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    jump (index) {

      return '/ruler?index=' + index
      // /ruler?index=1
      // if(this.$route.path == '/ruler'){
      //
      // }else {
      //   this.$router.push({
      //     path:'/ruler',
      //     query:{index:index}
      //   })
      // }
    },
    gojump (index) {
      if (this.$route.path == '/ruler') {
        this.$router.push({
          path: '/ruler',
          query: {index: index}
        })
        // window.reload()
      }
    }
  }
};
</script>

<style scoped>
  .footer-top {
    height: 48px;
    line-height: 48px;
    background: #393c5d;
    color: white;
    font-size: 18px;
    font-weight: 400;
  }

  .footer {
    width: 100%;
    padding: 0;
  }

  .foot-bg {
    width: 100%;
    height: 160px;
  }

  .foot-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 160px;
    font-size: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
  }

  .footer-bottom {
    position: relative;
    width: 100%;
    height: 389px;
    background: #2D3053;
  }

  .footer-container {
    margin: 0 auto;
    width: 1200px;
    height: 389px;
    color: #ffffff;
  }

  .container-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .link {
    font-weight: bold;
    font-size: 18px;
    mix-blend-mode: normal;
    margin-bottom: 10px;
  }


  .link-list a {
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    margin: 0 20px;
  }

  .link-list a:hover {
    color: #185CFF;
  }

  .contract-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 60px;
    mix-blend-mode: normal;
    margin-top: -80px;
  }

  .info {
    min-width: 380px;
    height: 95px;
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .info-sub {
    line-height: 44px;
    height: 44px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .qr-code {
    width: 130px;
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .code {
    width: 143px;
    height: 143px;
    margin: 10px 0 10px 0;
    /* background-image: url("../assets/qxtQRcode.png"); */
  }

  .code-img {
    width: 100%;
    height: 100%;
  }

  .code-title {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: 5px;
  }

  .record-info {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
    /* color: #999999; */
    mix-blend-mode: normal
  }

  .record-info a {
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: #999999;
  }

  .footer-icon {
    margin-right: 6px;
    font-size: 14px;
  }

  .foot-ruler {
    color: #ffffff;

  }

  .foot-ruler a {
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    line-height: 36px;
    margin-right: 36px;
  }

  .foot-ruler a:hover {
    color: #185CFF;
  }

  .callPhone {
    background: linear-gradient(87deg, #4684FE 0%, #1D60FA 100%);
    color: white;
    padding: 10px 40px;
    line-height: 40px;
    height: 40px;
    border-radius: 20px;
    width: 136px;
    font-size: 14px;
    font-weight: 400;
  }
</style>
