<template>
  <div style="width: 100vw; height: 100vh" class="res">
    <img
      class="comimg"
      style="width: 100%; height: 100%"
      src="@/assets/use/usebg.png"
    />
    <div class="abs user-view">
      <img
        style="width: 17.5vw; height: 15.56vh"
        src="@/assets/use/uselogo.png"
      />
      <div class="usercon cflex">
        <div class="user-tit">马上试用企修通</div>
        <div class="user-sub">
          感谢您留下宝贵的信息，稍后我们会有工作人员联系您
        </div>
        <el-form
          ref="ruleForm"
          :rules="rules"
          :model="ruleForm"
          label-width="0px"
        >
          <el-form-item label="" prop="companyName">
            <el-input
              style="width: 20.8vw"
              v-model="ruleForm.companyName"
              placeholder="公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="contactorName">
            <el-input
              v-model="ruleForm.contactorName"
              placeholder="联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="contactorPhone">
            <el-input
              style="width: 20.8vw"
              v-model="ruleForm.contactorPhone"
              placeholder="联系人电话"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="user-submit cflex"
              @click="onSubmit('ruleForm')"
              :loading="loading"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="user-buttom">
        渝ICP备20000612号-1 渝公网安备 50019002504160号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "free",
  data() {
    var validateMobile = (rule, value, callback) => {
      let reg = /^1[3-9]\d{9}$/;
      if (value == "" || !value) {
        callback(new Error("请输入联系人电话"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请检查手机格式"));
        } else {
          callback();
        }
      }
    };
    return {
      loading: false,
      ruleForm: {
        // companyName: "",
        // contactorName: "",
        // contactorPhone: "",
      },
      rules: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        contactorName: [
          { required: true, message: "请输入联系人名称", trigger: "blur" },
        ],
        contactorPhone: [{ validator: validateMobile, trigger: "blur" }],
      },
    };
  },
  methods: {
    onSubmit(formName) {
      let that = this;
      that.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.loading = true;
          this.$api.saveMessage(that.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "您的试用申请已提交，请耐心等待工作人员联系",
                type: "success",
              });
              that.loading = false;
              // this.$router.push({ path: "/" });
              this.$router.go(-1);
            } else if (res.code == 500 && res.msg == "您已提交过此申请") {
              this.$message({
                message: res.msg,
                type: "warning",
              });
              that.loading = false;
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        } else {
          that.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.user-view {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.usercon {
  width: 35.7vw;
  height: 55.27vh;
  box-sizing: border-box;
  background: linear-gradient(180deg, #f9fcff 0%, #d4e2ff 100%);
  box-shadow: 0px 0px 20px 1px rgba(31, 95, 255, 0.1);
  border-radius: 18px 18px 18px 18px;
  border: 2px solid #ffffff;
  flex-direction: column;
}

.user-tit {
  font-size: 32px;
  font-weight: 600;
  color: #1f5fff;
  line-height: 36px;
}

.user-sub {
  font-size: 16px;
  font-weight: 400;
  color: #717477;
  line-height: 22px;
  margin-top: 12px;
  margin-bottom: 42px;
}

.user-submit {
  width: 20.8vw;
  height: 52px;
  background: linear-gradient(180deg, #4189ff 0%, #1f5fff 100%);
  border-radius: 26px 26px 26px 26px;
  color: white;
  margin-top: 20px;
  cursor: pointer;
}

.user-buttom {
  font-size: 12px;
  font-weight: 400;
  color: #717477;
  line-height: 18px;
  text-align: center;
  margin-top: 12vh;
}
</style>
