<template>
  <div>
    <div>
      <img
        class="comimg"
        style="width: 100%"
        src="@/assets/product/banner.png"
      />
    </div>
    <div class="res" style="height: 712px">
      <img class="comimg" style="width: 100%" src="@/assets/product/bg55.png" />
      <div class="abs home-p-com">
        <div class="com-page-t1">数字化运维模块</div>
        <div
          style="
            height: 361px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;
          "
        >
          <div style="width: 251px">
            <img
              style="width: 100%"
              class="p-o-img"
              src="@/assets/product/ofs-i-user-shuzihua.png"
            />
            <div class="p-o-tit">用户</div>
          </div>
          <vue-seamless-scroll
            :data="listData"
            :class-option="classOption"
            class="warp"
          >
            <ul class="ul-item">
              <li
                class="li-item"
                v-for="(item, index) in listData"
                :key="index"
              >
                <img style="height: 39px" src="@/assets/product/jiantou2.png" />
              </li>
            </ul>
          </vue-seamless-scroll>
          <div style="width: 343px">
            <img
              style="width: 100%"
              class="p-o-img"
              src="@/assets/product/ofs-i-qixiutong-shuzihua.png"
            />
            <div class="p-o-tit">企修通平台</div>
          </div>
          <vue-seamless-scroll
            :data="listData"
            :class-option="classOption"
            class="warp"
          >
            <ul class="ul-item">
              <li
                class="li-item"
                v-for="(item, index) in listData"
                :key="'a' + index"
              >
                <img style="height: 39px" src="@/assets/product/jiantou2.png" />
              </li>
            </ul>
          </vue-seamless-scroll>
          <div style="width: 207px">
            <img
              style="width: 100%"
              class="p-o-img"
              src="@/assets/product/ofs-i-gongchengshi-shuzihua.png"
            />
            <div class="p-o-tit">运维工程师</div>
          </div>
        </div>
        <div
          style="
            height: 64px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 13px;
          "
        >
          <vue-seamless-scroll
            :data="listData"
            :class-option="classOption1"
            class="warp"
            style="width: 39px; height: 64px; margin-left: 98px"
          >
            <ul class="ul-item">
              <li
                class="li-item"
                v-for="(item, index) in listData"
                :key="'c' + index"
              >
                <img style="width: 39px" src="@/assets/product/jiantou3.png" />
              </li>
            </ul>
          </vue-seamless-scroll>
          <vue-seamless-scroll
            :data="listData"
            :class-option="classOption2"
            class="warp"
            style="width: 39px; height: 64px; margin-right: 98px"
          >
            <ul class="ul-item">
              <li
                class="li-item"
                v-for="(item, index) in listData"
                :key="'b' + index"
              >
                <img style="width: 39px" src="@/assets/product/jiantou4.png" />
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
        <div
          style="
            margin: 0 auto;
            background: rgba(24, 92, 255, 0.07);
            border-radius: 4px;
            width: 1014px;
            height: 8px;
            opacity: 0.72;
          "
        ></div>
      </div>
    </div>
    <div class="res" style="height: 1087px">
      <img class="comimg" style="width: 100%" src="@/assets/product/bg44.png" />
      <div class="abs home-p-com">
        <div
          style="
            display: flex;
            margin-top: 100px;
            height: 495px;
            align-items: center;
          "
        >
          <img style="width: 825px" src="@/assets/product/图.png" />
          <div style="flex: 1">
            <div
              style="
                font-size: 40px;
                font-weight: 600;
                line-height: 48px;
                margin-top: -20px;
              "
            >
              报修问题，一码解决全程在线
            </div>
            <div style="margin-top: 77px" class="p-two-com">
              企修通运维模块，一键扫码报修
            </div>
            <div style="margin: 40px 0" class="p-two-com">
              维修过程全节点实时在线，实现服务质量可视化评估，
            </div>
            <div class="p-two-com">致力于为您提供优质服务。</div>
          </div>
        </div>
        <div class="modules">
          <div
            class="moduleBox res"
            :style="{
              marginLeft: index == 0 || index == 3 ? '0px' : '30px',
              marginTop: index < 3 ? '0px' : '30px',
            }"
            v-for="(item, index) in modulesData"
            :key="index"
          >
            <div class="moduleTitle">{{ item.title }}</div>
            <div class="moduleText">{{ item.text }}</div>
            <img class="p-two-dz abs" src="@/assets/product/点缀.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 974px">
      <img class="comimg" style="width: 100%" src="@/assets/product/bg33.png" />
      <div class="abs home-p-com">
        <div class="com-page-t1">云部署盘点模块</div>
        <el-tabs
          style="margin-top: 56px"
          v-model="activeName"
          @tab-click="tabClick"
        >
          <el-tab-pane name="first">
            <span slot="label"
              ><i class="iconfont icon-ofs-i-ruanjian p-three-icon"></i>
              软/硬件结合</span
            >
            <div style="height: 641px; margin-top: 20px" class="betflex">
              <div style="flex: 1; margin: 0 115px 0 98px">
                <div class="p-three-o">软/硬件结合</div>
                <div class="p-three-t">
                  软件系统与硬件设备搭配使用大幅提升盘点效率
                </div>
                <div class="four-btn" @click="giveContract">了解更多</div>
              </div>
              <img
                style="width: 724px; height: 641px"
                src="@/assets/product/tu.png"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane name="second">
            <span slot="label"
              ><i class="iconfont icon-ofs-i-pandian p-three-icon"></i>
              非接触式批量盘点</span
            >
            <div style="height: 641px; margin-top: 20px" class="betflex">
              <div style="flex: 1; margin: 0 45px 0 98px">
                <div class="p-three-o">非接触式批量盘点</div>
                <div class="p-three-t">
                  基于RFID射频技术<br />
                  实现远距离快速批量采集资产信息
                </div>
                <div class="four-btn" @click="giveContract">了解更多</div>
              </div>
              <img
                style="width: 724px; height: 641px"
                src="@/assets/product/tu1.png"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane name="third">
            <span slot="label"
              ><i class="iconfont icon-ofs-i-duankou p-three-icon"></i>
              多端口对接</span
            >
            <div style="height: 641px; margin-top: 20px" class="betflex">
              <div style="flex: 1; margin: 0 115px 0 98px">
                <div class="p-three-o">多端口对接</div>
                <div class="p-three-t">
                  盘点数据自动生成数据报表支持海量数据导入、导出
                </div>
                <div class="four-btn" @click="giveContract">了解更多</div>
              </div>
              <img
                style="width: 724px; height: 641px"
                src="@/assets/product/tu2.png"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="res" style="height: 657px">
      <img class="comimg" style="width: 100%" src="@/assets/product/bg22.png" />
      <div class="abs home-p-com">
        <div class="com-page-t1">线上化资产处置模块</div>
        <div
          style="
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            margin-top: 8px;
          "
        >
          选择企修通的3大理由
        </div>
        <div class="p-four-v">
          <div
            v-for="(item, index) in fourList"
            :key="'zc' + index"
            class="cflex p-four-conview"
          >
            <img style="width: 82px; height: 82px" :src="item.icon" />
            <div class="p-four-zc">{{ item.content }}</div>
            <div class="p-four-detail">{{ item.detail }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 601px">
      <img class="comimg" style="width: 100%" src="@/assets/product/bg11.png" />
      <div class="abs home-p-com">
        <div class="com-page-t1">高效回收</div>
        <div class="betflex p-five-bg">
          <div
            v-for="(item, index) in fiveList"
            :key="'five' + index"
            class="cflex"
            style="flex-direction: column"
          >
            <img v-if="item.icon" class="p-five-line" :src="item.icon" />
            <div v-if="!item.icon" class="p-five-round">0{{ index + 1 }}</div>
            <div v-if="!item.icon" class="p-five-tt">{{ item.content }}</div>
            <div v-if="!item.icon" class="p-five-detail">{{ item.detail }}</div>
            <div v-if="!item.icon" class="p-five-detail">
              {{ item.detailsub }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 284.2px">
      <img class="comimg" style="width: 100%" src="@/assets/product/bg.png" />
      <div
        class="abs home-p-com cflex"
        style="flex-direction: column; color: white"
      >
        <div
          style="
            margin-bottom: 20px;
            font-size: 40px;
            font-weight: 600;
            text-align: center;
          "
        >
          专业的事交给专业的人来做，更放心！
        </div>
        <div class="e-btn" @click="giveContract">了解更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  name: "ProductIntroduction",
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      fourList: [
        {
          icon: require("@/assets/product/ofs-i-shujuanquan.png"),
          content: "数据安全",
          detail: "data security",
        },
        {
          icon: require("@/assets/product/ofs-i-huanbaochuzhi.png"),
          content: "环保处置",
          detail: "Environmental  disposal",
        },
        {
          icon: require("@/assets/product/ofs-i-helicanzhi.png"),
          content: "合理残值",
          detail: "Reasonable residual value",
        },
      ],
      fiveList: [
        {
          icon: null,
          content: "资产统计",
          detail: "对需要处置的闲置资产进行",
          detailsub: "简单的清点统计",
        },
        {
          icon: require("@/assets/product/ofs-i-buzhou.png"),
        },
        {
          icon: null,
          content: "上传清单",
          detail: "线上评估资产价值",
          detailsub: "获取最优报价",
        },
        {
          icon: require("@/assets/product/ofs-i-buzhou.png"),
        },
        {
          icon: null,
          content: "签约回收",
          detail: "可根据企业的不同需求场景",
          detailsub: "提供相应的处置报告",
        },
      ],
      activeName: "first",
      listData: [1, 2, 3, 4],
      classOption: {
        limitMoveNum: 2,
        direction: 3,
        hoverStop: false,
      },
      classOption1: {
        limitMoveNum: 2,
        direction: 1,
        hoverStop: false,
      },
      classOption2: {
        limitMoveNum: 2,
        direction: 0,
        hoverStop: false,
      },
      modulesData: [
        {
          title: "一机一码",
          text: "用户使用微信一键扫码，轻松报修",
        },
        {
          title: "报修形式丰富",
          text: "支持文字、图片、视频等多种形式",
        },
        {
          title: "报修进度实时同步",
          text: "订单进度实时可见,无需人工催促",
        },
        {
          title: "系统指派",
          text: "系统自动指派，工程师及时响应",
        },
        {
          title: "服务评价",
          text: "服务线上评价，问题一对一解决",
        },
        {
          title: "数字化监控仓",
          text: "实时监控运维过程，智能分析数据",
        },
      ],
    };
  },
  mounted() {
    this.$parent.gotoTop();
  },
  methods: {
    tabClick(item) {
      this.activeName = item.name;
    },
    giveContract() {
      //  留下联系方式
      this.$router.push("/free");
    },
  },
};
</script>

<style scoped>
.p-three-icon {
  font-size: 26px;
}

.e-btn {
  width: 124px;
  height: 49px;
  line-height: 49px;
  background: #ffffff;
  color: #185cff;
  box-shadow: 0px 3px 6px 1px rgba(24, 92, 255, 0.16);
  font-weight: 400;
  font-size: 16px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.p-five-bg {
  height: 240px;
  margin-top: 88px;
}

.p-five-line {
  width: 96px;
  height: 26px;
  margin-top: 50px;
}

.p-five-tt {
  font-size: 28px;
  font-weight: 600;
  color: #171717;
  margin: 40px 0 20px 0;
}

.p-five-detail {
  font-size: 20px;
  font-weight: 400;
  color: #35363f;
}

.p-five-round {
  width: 84px;
  height: 84px;
  border-radius: 42px;
  line-height: 84px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 40px;
  background: linear-gradient(180deg, #4189ff 0%, #1f5fff 100%);
  box-shadow: 6px 6px 6px 1px rgba(53, 54, 63, 0.1);
}

.p-four-conview {
  width: 376px;
  height: 251px;
  flex-direction: column;
  background: linear-gradient(180deg, #ffffff 0%, #e9f0ff 100%);
  box-shadow: 0px 10px 10px 1px rgba(18, 60, 165, 0.05);
  border-radius: 18px;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.p-four-conview:hover {
  margin-top: -10px;
}

.p-four-v {
  display: flex;
  justify-content: space-between;
  height: 251px;
  margin-top: 72px;
}

.p-four-zc {
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0 9px 0;
}

.p-four-detail {
  font-size: 16px;
  font-weight: 400;
  color: #d4d4d4;
}

.p-three-o {
  font-size: 24px;
  color: #35363f;
  font-weight: 600;
}

.p-three-t {
  margin: 42px 0 44px 0;
  font-size: 20px;
  font-weight: 400;
  color: #717477;
  line-height: 32px;
}

.four-btn {
  background: #185cff;
  color: white;
  box-shadow: 0px 3px 6px 1px rgba(24, 92, 255, 0.16);
  border-radius: 4px;
  font-weight: 400;
  padding: 13px 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 35px;
  display: inline-block;
}

.p-two-com {
  font-weight: 400;
  font-size: 24px;
  color: #35363f;
}

.p-o-img {
  /*border: 1px dashed #292D33;*/
}

.p-o-tit {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-top: 14px;
}

.warp {
  width: 129px;
  height: 39px;
  overflow: hidden;
}

.modules {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 85px;
}

.moduleBox {
  box-sizing: border-box;
  padding: 30px;
  width: 380px;
  height: 138px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(229, 236, 254, 0.16) 100%
  );
  border-radius: 18px 18px 18px 18px;
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 0.95);
}

.p-two-dz {
  top: -13px;
  right: -10px;
  width: 32px;
  height: 36px;
}

.moduleTitle {
  height: 40px;
  font-size: 28px;
  font-weight: 600;
  color: #1f5fff;
  line-height: 40px;
}

.moduleText {
  margin-top: 10px;
  height: 28px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 400;
  color: #707070;
}

.cloudDeploymentModule {
  padding-top: 100px;
  background-repeat: no-repeat;
}
</style>
